<template>
    <mnSup mostrar="true" />
    <main id="auditorio">
        <div class="boxVideo">
            <img src="assets/img/audit-ilustra.svg" alt="">
        <!--     <div class="btnVerPrograma">
                <a href="#programa">ver programa</a>
            </div> -->
            <div class="boxStreaming">
                <ytFrame ytLink="https://youtube.com/embed/_CcTx5VTFdw?feature=share" />
            </div>
        </div>
     <!--   <div class="programa" id="programa">
            <h1>PROGRAMA</h1>
                <div class="fotoPrograma">
                <img src="assets/img/audit-programa.svg" alt="">
            </div> 
            <div class="calendario">
                <div class="boxDay">
                    <h2>18 Enero</h2>
                    <div class="boxItemPrograma">
                        <h4>ceremonia de inauguración</h4>
                        <p>Charla Javier Santaolalla</p>
                        <h3>10:00 AM</h3>
                        <span>*Abierto a todo público</span>
                    </div>
                </div>
                <div class="boxDay">
                    <h2>19-20 Enero</h2>
                    <div class="boxItemPrograma">
                        <h4>proceso de evaluación proyectos seleccionados</h4>
                        <span class="sala">Salas 1-2-3</span>
                        <h3>9:00 AM</h3>
                        <span class="privacidad">*Acceso restringido</span>
                    </div>
                </div>
                <div class="boxDay">
                    <h2>21 Enero</h2>
                    <div class="grid-flex">
                        <div class="boxItemPrograma">
                            <h4>charla</h4>
                            <p>Andrea Leisewitz y Alejandra Santana</p>
                            <h3>10:00 AM</h3>
                            <span class="privacidad">*Abierto a todo público</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 3</span>
                            <p>Tatiana López</p>
                            <h3>10:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 4</span>
                            <p>Carla Christie</p>
                            <h3>10:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                    </div>
                </div>
                <div class="boxDay">
                    <h2>22 Enero</h2>
                    <div class="boxItemPrograma">
                        <h4>clausura congreso</h4>
                        <p>Charla Bárbara Aravena y Sergio Soto</p>
                        <h3>11:00 AM</h3>
                        <span class="privacidad">*Abierto a todo público</span>
                    </div>
                </div>

            </div>
            <div class="calendario pt2">
                <div class="boxDay">
                    <div class="boxItemPrograma">
                        <h4>conversatorio</h4>
                        <p>Javier Santaolalla</p>
                        <a href="https://us02web.zoom.us/j/83249561365?pwd=Q3Q3MFYvYkxTK0ROc3lGc3lxNHRFUT09" target="_blank">Ingresar</a>
                        <span>*Acceso restringido equipos Congreso Explora</span>
                    </div>
                </div>
                <div class="boxDay">
                </div>
                <div class="boxDay">
                    <div class="grid-flex">
                        <div class="boxItemPrograma">
                            <h4>talleres para docentes</h4>
                            <span class="sala">Sala 1</span>
                            <p>Andrea Leisewitz</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>talleres para docentes</h4>
                            <span class="sala">Sala 2</span>
                            <p>Alejandra Santana</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 3</span>
                            <p>César González</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 4</span>
                            <p>Verónica Donoso</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                    </div>
                    <div class="grid-flex">
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 3</span>
                            <p>Rodolfo Sapiains</p>
                            <h3>12:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                    </div>
                </div>
                <div class="boxDay">
                </div>
            </div>

            <div class="calendario mobile">
                <div class="boxDay">
                    <h2>18 Enero</h2>
                    <div class="boxItemPrograma">
                        <h4>ceremonia de inauguración</h4>
                        <p>Charla Javier Santaolalla</p>
                        <h3>10:00 AM</h3>
                        <span>*Abierto a todo público</span>
                    </div>
                    <div class="boxItemPrograma">
                        <h4>conversatorio</h4>
                        <p>Javier Santaolalla</p>
                        <a href="https://us02web.zoom.us/j/83249561365?pwd=Q3Q3MFYvYkxTK0ROc3lGc3lxNHRFUT09" target="_blank">Ingresar</a>
                        <span>*Acceso restringido equipos Congreso Explora</span>
                    </div>
                </div>
                <div class="boxDay">
                    <h2>19-20 Enero</h2>
                    <div class="boxItemPrograma">
                        <h4>proceso de evaluación proyectos seleccionados</h4>
                        <span class="sala">Salas 1-2-3</span>
                        <h3>9:00 AM</h3>
                        <span class="privacidad">*Acceso restringido</span>
                    </div>
                </div>
                
                <div class="boxDay">
                    <h2>21 Enero</h2>
                    <div class="grid-flex">
                        <div class="boxItemPrograma">
                            <h4>charla</h4>
                            <p>Andrea Leisewitz y Alejandra Santana</p>
                            <h3>10:00 AM</h3>
                            <span class="privacidad">*Abierto a todo público</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 3</span>
                            <p>Tatiana López</p>
                            <h3>10:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 4</span>
                            <p>Carla Christie</p>
                            <h3>10:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                    </div>
                    <div class="grid-flex">
                        <div class="boxItemPrograma">
                            <h4>talleres para docentes</h4>
                            <span class="sala">Sala 1</span>
                            <p>Andrea Leisewitz</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>talleres para docentes</h4>
                            <span class="sala">Sala 2</span>
                            <p>Alejandra Santana</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 3</span>
                            <p>César González</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                        <div class="boxItemPrograma">
                            <h4>conversando con científicos y científicas</h4>
                            <span class="sala">Sala 4</span>
                            <p>Verónica Donoso</p>
                            <h3>11:00 AM</h3>
                            <router-link to="/evaluaciones">Ingresar</router-link>
                            <span class="privacidad">*Acceso Restringido</span>
                        </div>
                    </div>
                </div>
                <div class="boxDay">
                    <h2>22 Enero</h2>
                    <div class="boxItemPrograma">
                        <h4>clausura congreso</h4>
                        <p>Charla Bárbara Aravena y Sergio Soto</p>
                        <h3>11:00 AM</h3>
                        <span class="privacidad">*Abierto a todo público</span>
                    </div>
                </div>

            </div>
        </div>-->
    </main>
    <footerLogo />
    <modal modal="satisfaccion"/>
    <article class="linkEncuesta" v-if="linkEncuesta!=''">
        <p>Danos tu opinión de la actividad.</p>
        <a :href="linkEncuesta">Ir a la encuesta</a>
    </article>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import mnSup from '@/components/mnSup.vue'
import footerLogo from '@/components/footerLogo.vue'
import ytFrame from '@/components/ytFrame.vue'
import modal from '@/components/modal.vue'

export default {
    name: 'Anfiteatro',
    components: {
        mnSup, footerLogo, ytFrame, modal
    },
    data(){
        return{
            linkEncuesta:''
        }
    },
    created(){
        document.title = 'ANFITEATRO - XXI Congreso Nacional Explora'
        this.iniciarModal();
    },
    methods:{
        ...mapMutations(['showModal']),
        async iniciarModal(){
            let programa=[];
            let url = `/bdd/programa.json`;
            try{
                let rs = await fetch(url);
                let data = await rs.json();

                programa = data;
            }catch(e){
                console.log(e);
            }

            const hoy = new Date();
            for(let enc of programa){
                let fha = enc.fecha_activa.split(' ');
                let fa = fha[0].split('-');
                let ha = fha[1].split(':');
                let f_activa = new Date(fa[2], fa[1]-1, fa[0], ha[0], ha[1]);

                let fht = enc.fecha_termino.split(' ');
                let ft = fht[0].split('-');
                let ht = fht[1].split(':');
                let f_termino = new Date(ft[2], ft[1]-1, ft[0], ht[0], ht[1]);

                if(hoy >= f_activa && hoy <= f_termino){
                    this.linkEncuesta = `/encuesta-satisfaccion/${enc.codigo}`;
                    this.showModal()
                }
            }
        }
    },
    computed:{
        ...mapState(['apiURL'])
    }
}
</script>

<style lang="scss" scoped>
main#auditorio{
    padding:20px;
    background-color: #fff;
    background-image: url('../../public/assets/img/audit-fondo.jpg');
    background-size: 100% auto;
    background-position: -2px center;
    background-repeat: no-repeat;
    background-position-x: right;
    .boxVideo{
        margin-bottom:80px;
        position: relative;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            position: absolute;
            top:0px;
            left: 0px;
            width: 100%;
            height: auto;
            z-index: 0;
        }
        .btnVerPrograma{
            position: absolute;
            top:46%;
            right:5.2%;
            padding:5px;
            background-color:var(--color1);
            a{
                display: inline-block;
                background-color:var(--color2);
                color:#fff;
                padding:15px 20px;
                border-radius: 50px;
                text-transform: uppercase;
                font-weight: 900;
            }
            @media(max-width:1100px){
                display: none;
            }
        }
        .boxStreaming{
            position: relative;
            top:-48px;
            width:50vw;
            max-width: 1000px;
            height: 50vh;
            background-color: var(--color1);
            margin-top:30px;
            z-index:5;

            padding:13px;
            border:4px solid #000;
        }
        @media(max-width:1100px){
            height: auto;
            margin-bottom:40px;
            .boxStreaming{
                top:0px;
                width:100%;
                max-width: 1000px;
                height: 26vh;
                background-color: var(--color1);
                margin-top:30px;
                z-index:5;

                padding:5px;
                border:4px solid #000;
            }
        }
    }
    .contenido{
        max-width: 1000px;
        width: 100%;
        min-height: calc(100vh - 280px);
        margin: auto;
        
    }
    #programa{
        h1{
            text-align: center;
            font-weight: 900;
            padding:20px
        }
        .calendario{
            display: grid;
            grid-template-columns: 230px 230px 1fr 230px;
            gap:20px;

            @media(max-width:1100px){
                display:none;
            }
            &.pt2{
                grid-template-columns: 230px 150px 1fr 150px;
            }
            
            
            .boxDay{
                h2{
                    background-color: #000;
                    color:#fff;
                    text-transform: uppercase;
                    font-weight: 900;
                    font-size: 1.3rem;
                    padding:7px 20px;
                    border-radius: 50px;
                    width: 100%;
                    margin: auto;
                    text-align: center;
                }
                .grid-flex{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap:10px;
                    padding: 0px 20px;
                    .boxItemPrograma{
                        margin: 0px;
                        margin-bottom: 10px;
                        width: 100%;
                        max-width: 190px;
                    }
                }
                .boxItemPrograma{
                    background-color: var(--color1);
                    border:1px solid #000;
                    width: 190px;
                    height: 190px;
                    margin: auto;
                    margin-bottom: 10px;
                    padding:10px;
                    font-size: .75rem;
                    text-align: center;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    h4{
                        text-transform: uppercase;
                        color:var(--color2);
                        line-height: 1.2;
                        margin-bottom: 6px;
                    }
                    span.sala{
                        display: block;
                        text-transform: uppercase;
                        font-weight: 900;
                        font-size: 1.1em;
                        color:var(--color2);
                        margin-bottom: 8px;
                    }
                    h3{
                        font-weight: 900;
                        font-size: 1.6em;
                    }
                    a{
                        display: inline-block;
                        background-color: var(--color2);
                        color:#fff;
                        font-weight: 900;
                        font-size: 1.1em;
                        text-transform: uppercase;
                        padding:6px 15px;
                        border-radius: 50px;
                        &:hover{
                            background-color: #000;
                            text-decoration: none;
                        }
                    }
                }
            }
            &.mobile{
                display: none;
                @media(max-width:1100px){
                    display:grid;
                    grid-template-columns: 1fr;
                    .grid-flex{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        gap:10px;
                        padding: 0px 20px;
                        .boxItemPrograma{
                            margin: 0px;
                            margin-bottom: 10px;
                            width: 100%;
                            max-width: 135px;
                        }
                    }
                }
            }
            
        }
    }
}
article.linkEncuesta{
    position: absolute;
    bottom:30px;
    left:calc(50% - 150px);
    z-index: 20;
    width: 300px;
    padding:15px;

    background-color: var(--color1);
    border:2px solid #000;
    border-radius: 17px;
    box-shadow:0px 0px 10px rgba(0,0,0,0.6);

    text-align: center;
}
</style>